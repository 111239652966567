export enum EnglishLanguages {
	EN = 'en',
	EN_GB = 'en_GB',
}

export enum ButtonType {
	QUICK_REPLY = 'QUICK_REPLY',
	CALL_TO_ACTION_URL = 'URL',
	COPY_CODE = 'COPY_CODE',
	OTP = 'OTP',
}

export enum FormFieldIds {
	BODY_TEXT = 'bodyText',
	BUTTONS = 'buttons',
	TEXT_HEADER_INPUT = 'headerInputValue',
	FOOTER_INPUT = 'footerInput',
	FILE_URL_DOC = 'fileUrlDoc',
	MEDIA_DOC = 'mediaDoc',
	VIDEO = 'video',
	FILE_URL = 'fileUrl',
}

export enum Content {
	INTERACTIVE = 'INTERACTIVE',
	CAROUSEL = 'CAROUSEL',
	LTO = 'LIMITED_TIME_OFFER',
	AUTHENTICATION = 'AUTHENTICATION',
}

export enum VariablesMapKey {
	HEADER = 'header',
	BODY = 'body',
	BUTTON = 'button',
	CAROUSEL = 'carousel',
	VERIFICATION_CODE = 'verification_code',
}

export enum MessageTemplateSortOptions {
	NAME_ASC = 'NAME_ASC',
	NAME_DESC = 'NAME_DESC',
	CREATED_FIRST = 'CREATED_FIRST',
	CREATED_LAST = 'CREATED_LAST',
}

export enum TemplateTypeTexts {
	TEXT_AND_MEDIA = 'Text & Media',
	CAROUSEL = 'Carousel',
	LTO = 'Offer with Coupon',
	AUTHENTICATION = 'Authentication',
}

export enum TemplateCategory {
	UTILITY = 'UTILITY',
	AUTHENTICATION = 'AUTHENTICATION',
	MARKETING = 'MARKETING',
}

export enum TemplateStatus {
	APPROVED = 'approved',
	REJECTED = 'rejected',
	SUBMITTED = 'submitted',
	CREATED = 'created',
	PENDING = 'PENDING',
}

export enum MESSAGE_TEMPLATE_TUTORIAL {
	TEMPLATE_V2_TUTORIAL = 'CREATE_MESSAGE_TEMPLATES_V2_TUTORIAL',
	LTO_TUTORIAL = 'CREATE_MESSAGE_TEMPLATES_V2_TUTORIAL_LTO',
	AUTHENTICATION_TUTORIAL = 'CREATE_MESSAGE_TEMPLATES_V2_TUTORIAL_AUTHENTICATION',
}
